export default [{
	path: '/:amp(amp)?/product',
	component: () => import(/* webpackChunkName: 'Product' */ './ProductHome.vue'),
	props: true,
	children: [
		{
			path: ':code?',
			name: 'product',
			component: () => import(/* webpackChunkName: 'Product' */ './ProductIndex.vue'),
			props: true
		},
		{ path: '*', redirect: '/product' }
	]
},
{
	path: '/:amp(amp)?:/features',
	children: [
		{
			path: ':code?',
			redirect: '/:amp(amp)?/product/:code?'
		},
		{ path: '*', redirect: '/:amp(amp)?/product' }
	]
}];