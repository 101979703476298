export default [{
	path: '/:amp(amp)?/support',
	component: () => import(/* webpackChunkName: 'Support' */ './SupportHome.vue'),
	props: true,
	children: [
		{
			path: '',
			name: 'support',
			component: () => import(/* webpackChunkName: 'Support' */ './SupportIndex.vue'),
			props: true
		},
		{
			path: 'faq',
			alias: '7/faq',
			name: 'faq',
			component: () => import(/* webpackChunkName: 'Support' */ './FAQIndex.vue'),
			props: true
		},
		{
			alias: 'faq/tags/:tag',
			path: '7/faq/tags/:tag',
			name: 'faq-tag',
			component: () => import(/* webpackChunkName: 'Support' */ './FAQIndex.vue'),
			props: true
		},
		{
			path: ':cfid/faq/:slug?',
			name: 'faq-article',
			component: () => import(/* webpackChunkName: 'Support' */ './FAQIndex.vue'),
			props: true
		},
		{
			path: '476/data-integration/cognito-forms-api/api-reference',
			name: 'cognito-forms-api-documentation',
			component: () => import(/* webpackChunkName: 'Support' */ './PublicApiDocumentation.vue'),
			props: true
		},
		{
			path: ':cfid/*',
			name: 'support-article',
			component: () => import(/* webpackChunkName: 'Support' */ './Support.vue'),
			props: true
		},
		{
			path: 'submit-request',
			name: 'submit-request',
			alias: '/supportrequest',
			component: () => import(/* webpackChunkName: 'SupportRequest' */ './SupportRequest.vue')
		},
		{
			path: 'report-abuse',
			name: 'report-abuse',
			component: () => import(/* webpackChunkName: 'ReportAbuse' */ './ReportAbuse.vue')
		},
		{
			alias: 'resources',
			path: '/:amp(amp)?/resources',
			name: 'resources',
			component: () => import(/* webpackChunkName: 'Resources' */ './Resources.vue'),
			props: true,
			meta: {
				title: 'Resources'
			}
		},
		{ path: '*', redirect: '/support' }
	]
}];