export default [{
	path: '/:amp(amp)?/legal',
	component: () => import(/* webpackChunkName: 'Legal' */ './LegalHome.vue'),
	props: true,
	children: [
		{ path: '', redirect: '/legal/terms' },
		{
			path: 'acceptable-use',
			component: () => import(/* webpackChunkName: 'Legal' */ './Legal.vue'),
			beforeEnter(to, from, next) {
				to.params.cfid = '1';
				next();
			},
			props: true
		},
		{
			path: 'privacy',
			component: () => import(/* webpackChunkName: 'Legal' */ './Legal.vue'),
			beforeEnter(to, from, next) {
				to.params.cfid = '2';
				next();
			},
			props: true
		},
		{
			path: 'terms',
			component: () => import(/* webpackChunkName: 'Legal' */ './Legal.vue'),
			beforeEnter(to, from, next) {
				to.params.cfid = '3';
				next();
			},
			props: true
		},
		{
			path: 'cookie-policy',
			component: () => import(/* webpackChunkName: 'Legal' */ './Legal.vue'),
			beforeEnter(to, from, next) {
				to.params.cfid = '4';
				next();
			},
			props: true
		},
		{
			path: ':cfid/*',
			component: () => import(/* webpackChunkName: 'Legal' */ './Legal.vue'),
			props: true
		}
	]
}];