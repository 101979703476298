<template>
	<router-view />
</template>

<script>
	import Vue from 'vue';
	import store from './store';
	import VueCookies from 'vue-cookies';

	Vue.use(VueCookies);

	export default {
		provide: {
			'$store': store
		},
		head: {
			titleTemplate: (title) => (title ? `${title} - Cognito Forms` : 'Cognito Forms - Easily Build Powerful Forms'),
			meta: [
				{
					name: 'viewport',
					content: 'width=device-width, initial-scale = 1.0, minimal-ui'
				},
				{
					name: 'apple-mobile-web-app-capable',
					content: 'yes'
				},
				{
					'http-equiv': 'X-UA-Compatible',
					content: 'IE=edge'
				},
				{ property: 'twitter:card', content: 'summary_large_image', id: 'twitter:card' },
				{ property: 'twitter:site', content: '@CognitoForms', id: 'twitter:site' },
				{ property: 'twitter:creator', content: '@CognitoForms', id: 'twitter:creator' },
				{ property: 'og:site_name', content: 'Cognito Forms', id: 'og:site_name' }
			],
			htmlAttrs: {
				lang: 'en'
			}
		}
	};
</script>