import './frame-busting';

import Vue from 'vue';
import App from './App.vue';
import Inline from '@cognitoforms/shared-components/src/components/content/Inline.vue';
import createRouter from './views/router';

import VueRouter from 'vue-router';
import VueGtag from 'vue-gtag';
import VueMeta from 'vue-meta';

import { isPrerender } from '@cognitoforms/utils/utilities';
import { disableTransitions } from './disable-transitions';
import { CognitoConfiguration } from '@cognitoforms/utils/clientside-configuration';
import 'what-input';

export default function createApp({ amp = false }) {
	const isProd = process.env.NODE_ENV === 'production';

	Vue.config.productionTip = false;

	Vue.filter('usd', value => {
		return value.toLocaleString('en-US', { style: 'currency', currency: 'USD' });
	});

	Vue.filter('number', value => {
		return value.toLocaleString('en-US');
	});

	// router
	Vue.use(VueRouter);
	const router = createRouter();

	// head
	Vue.use(VueMeta, {
		keyName: 'head',
		tagIDKeyName: 'id'
	});

	if (!isPrerender()) {
		Vue.use(VueGtag, {
			config: {
				id: CognitoConfiguration.GoogleAnalyticsId || 'G-TTBCW8BFKB'
			},
			pageTrackerTemplate(to) {
				return {
					page_title: to.meta.title,
					send_to: to.meta.auth === 'required' ?
						(CognitoConfiguration.AdminGoogleAnalyticsId || 'G-50TVNQ9EDE') :
						(CognitoConfiguration.GoogleAnalyticsId || 'G-TTBCW8BFKB')
				};
			}
		}, router);
	}
	else {
		disableTransitions();
	}

	// add global $amp property to all components
	Vue.prototype.$amp = amp;

	Vue.component('Inline', Inline);

	const app = new Vue({
		router,
		render: h => h(App)
	});

	Vue.config.performance = !isProd;
	Vue.config.devtools = !isProd;

	return { app, router };
}
