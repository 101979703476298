export default [{
	path: '/:amp(amp)?/company',
	component: () => import(/* webpackChunkName: 'About' */ './CompanyHome.vue'),
	props: true,
	children: [
		{ path: '', redirect: '/company/about' },
		{
			path: 'about',
			name: 'about',
			component: () => import(/* webpackChunkName: 'About' */ './CompanyIndex.vue'),
			beforeEnter(to, from, next) {
				to.params.cfid = '1';
				next();
			},
			props: true
		},
		{
			path: 'apply',
			alias: '12/*',
			component: () => import(/* webpackChunkName: 'Apply' */ './JobPosting.vue'),
			beforeEnter(to, from, next) {
				to.params.cfid = '12';
				next();
			},
			props: true
		},
		{
			path: 'career-fair',
			alias: '13/*',
			component: () => import(/* webpackChunkName: 'CareerFair' */ './JobPosting.vue'),
			beforeEnter(to, from, next) {
				to.params.cfid = '13';
				next();
			},
			props: true
		},
		{
			path: ':cfid/careers/*',
			component: () => import(/* webpackChunkName: 'JobPosting' */ './JobPosting.vue'),
			props: true
		},
		{
			path: 'careers',
			name: 'careers',
			meta: {
				header: 'light'
			},
			component: () => import(/* webpackChunkName: 'Careers' */ './CareersIndex.vue'),
			beforeEnter(to, from, next) {
				to.params.cfid = '2';
				next();
			},
			props: true
		},
		{
			path: ':cfid/*',
			name: 'company',
			component: () => import(/* webpackChunkName: 'About' */ './CompanyIndex.vue'),
			beforeEnter(to, from, next) {
				next();
			},
			props: true
		},
		{ path: '*', redirect: '/company' }
	]
}];