import { getProfile, getOrganizations } from '@cognitoforms/api/services/settings/account-service';
import { getVerificationInfo } from '@cognitoforms/api/services/authentication';
import { getAvatarSVG } from '@cognitoforms/utils/avatar';
import Vue from 'vue';

// Note: Derived from /svc/account/organizations response type
export type OrganizationMeta = {
	Id: string,
	Code: string,
	Name: string,
	Plan: string,
	OrgAction: string,
	Role: string,
	TotalOwners: number
}

let profileLock = true;
const state = Vue.observable({
	profile: {
		organizations: null as OrganizationMeta[],
		avatarUrl: null as string,
		firstName: null as string,
		lastName: null as string,
		email: null as string,
		avatarSeed: null as number,
		isVerified: null as boolean,
		mfaEnabled: false,
		mfaFeatureEnabled: false,
		mfaBackupEmail: null as string,

		get name() {
			return this.firstName + ' ' + this.lastName;
		},

		get avatar() {
			return (this.avatarUrl === '' || this.avatarUrl === ' ') && this.firstName ? getAvatarSVG(this.avatarSeed, this.firstName, this.lastName) : this.avatarUrl;
		},
		set avatar(value) {this.avatarUrl = value;}
	},
	formDocumentationVersion: 1
});

const store = Vue.observable({

	get formDocumentationVersion() {
		return state.formDocumentationVersion;
	},

	setFormDocumentationVersion(val) {
		state.formDocumentationVersion = val;
	},

	get profile() {
		return Object.assign({}, state.profile);
	},

	updateProfile(profileData: { firstName?: string, lastName?: string, email?: string, organizations?: any[], avatarUrl?: string, avatarSeed?: string, mfaEnabled?: boolean, mfaBackupEmail?: string }) {
		Object.assign(state.profile, profileData);
	},

	loadProfile() {
		if (profileLock) {
			profileLock = false;
			getProfile().then(response=>{
				profileLock = true;
				state.profile.avatarSeed = response.AvatarSeed;
				state.profile.firstName = response.FirstName;
				state.profile.lastName = response.LastName;
				state.profile.email = response.Email;
				state.profile.avatarUrl = response.AvatarUrl;
				state.profile.mfaEnabled = response.MfaEnabled;
				state.profile.mfaBackupEmail = response.MfaBackupEmail;
			});
			getOrganizations().then(response => {
				state.profile.organizations = response;
			});
			getVerificationInfo().then(response => {
				state.profile.isVerified = response;
			});
		}
	}
});

export default store;

export type Store = typeof store;
