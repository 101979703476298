export default [
	{
		path: '/templates/shared/:orgCode',
		alias: '/forms/templates/shared/:orgCode',
		component: () => import(/* webpackChunkName: 'SharedTemplates' */ './SharedTemplates.vue'),
		props: true,
		meta: {
			auth: 'optional'
		},
		children: [
			{
				path: '',
				component: () => import(/* webpackChunkName: 'SharedTemplates' */ './SharedTemplatesGrid.vue'),
				name: 'shared-templates'
			},
			{
				path: ':internalName',
				component: () => import(/* webpackChunkName: 'SharedTemplates' */ './SharedTemplatesPreview.vue'),
				props: true,
				children: [
					{
						path: 'copy',
						component: () => import(/* webpackChunkName: 'SharedTemplates' */ 'src/views/templates/CopyTemplateDialog.vue'),
						props: true,
						meta: {
							auth: 'required',
							loadManifest: true
						}
					}
				]
			},
			{ path: '*', redirect: '/templates' }

		]
	},
	{
		path: '/templates',
		alias: '/forms/templates',
		name: 'templates',
		component: () => import(/* webpackChunkName: 'Templates' */ './TemplatesHome.vue'),
		props: true,
		meta: {
			auth: 'optional'
		},
		children: [
			{
				path: 'search',
				name: 'templates-search',
				component: () => import(/* webpackChunkName: 'Templates' */ './TemplatesSearch.vue'),
				props: true
			},
			{
				path: ':cfid(\\d+)/:title',
				name: 'template-details',
				component: () => import(/* webpackChunkName: 'Templates' */ './Template.vue'),
				props: true,
				children: [
					{
						path: 'copy',
						component: () => import(/* webpackChunkName: 'Templates' */ 'src/views/templates/CopyTemplateDialog.vue'),
						props: true,
						meta: {
							auth: 'required',
							loadManifest: true
						}
					}
				]
			},
			{
				path: ':cfid(\\d+)/:tagName/:title',
				name: 'template-with-tag-details',
				component: () => import(/* webpackChunkName: 'Templates' */ './Template.vue'),
				props: true,
				children: [
					{
						path: 'copy',
						component: () => import(/* webpackChunkName: 'Templates' */ 'src/views/templates/CopyTemplateDialog.vue'),
						props: true,
						meta: {
							auth: 'required',
							loadManifest: true
						}
					}
				]
			},
			{
				path: ':tagName/:title',
				name: 'template-legacy-route',
				component: () => import(/* webpackChunkName: 'Templates' */ './TemplateIndex.vue'),
				props: true
			},
			{
				path: ':grouping?',
				name: 'template-index',
				component: () => import(/* webpackChunkName: 'Templates' */ './TemplateIndex.vue'),
				props: true
			},
			{ path: '*', redirect: '/templates' }
		]
	},
	{
		path: '/templates/industry',
		name: 'industries',
		component: () => import(/* webpackChunkName: 'Templates' */ './TemplatesHome.vue'),
		props: true,
		meta: {
			auth: 'optional'
		}
	},
	{
		path: '/templates/type',
		alias: '/form-types',
		name: 'form-types',
		component: () => import(/* webpackChunkName: 'Templates' */ './TemplatesHome.vue'),
		props: true,
		meta: {
			auth: 'optional'
		}
	}
];