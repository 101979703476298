export default [{
	path: '/:amp(amp)?/newsroom',
	component: () => import(/* webpackChunkName: 'Newsroom' */ './NewsroomHome.vue'),
	props: true,
	children: [
		{
			path: '',
			name: 'newsroom',
			component: () => import(/* webpackChunkName: 'Newsroom' */ './NewsroomIndex.vue'),
			props: true
		},
		{
			path: ':cfid(\\d{1,})/:pageName(press-releases|company-news)/*',
			name: 'newsroom-article-page',
			component: () => import(/* webpackChunkName: 'Newsroom' */ './NewsroomIndex.vue'),
			props: true
		},
		{
			path: ':pageName/',
			name: 'newsroom-page',
			component: () => import(/* webpackChunkName: 'Newsroom' */ './NewsroomIndex.vue'),
			props: true
		},
		{
			path: ':pageName/page/:page?',
			name: 'newsroom-pagination',
			component: () => import(/* webpackChunkName: 'Newsroom' */ './NewsroomIndex.vue'),
			props: true
		},

		{ path: '*', redirect: '/newsroom' }
	]
}];