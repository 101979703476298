export default [{
	path: '/:amp(amp)?/blog',
	component: () => import(/* webpackChunkName: 'Blog' */ './BlogHome.vue'),
	props: true,
	children: [
		{
			path: '',
			name: 'blog',
			component: () => import(/* webpackChunkName: 'Blog' */ './BlogIndex.vue'),
			props: true
		},
		{
			path: 'page/:page?',
			name: 'blog-pagination',
			component: () => import(/* webpackChunkName: 'Blog' */ './BlogIndex.vue'),
			props: (route) => {
				let page = Number.parseInt(route.params.page, 10);
				if (Number.isNaN(page)) {
					page = 1;
				}
				return { ...route.params, page };
			}
		},
		{
			path: 'tags/:tag',
			name: 'blog-tag',
			component: () => import(/* webpackChunkName: 'Blog' */ './BlogIndex.vue'),
			props: true
		},
		{
			path: 'tags/:tag?/page/:page?',
			name: 'blog-tag-pagination',
			component: () => import(/* webpackChunkName: 'Blog LP' */ './BlogIndex.vue'),
			props: (route) => {
				let page = Number.parseInt(route.params.page, 10);
				if (Number.isNaN(page)) {
					page = 1;
				}
				return { ...route.params, page };
			}
		},
		{
			path: 'l/:cfid/*',
			name: 'blog-landing-page',
			component: () => import(/* webpackChunkName: 'Blog' */ './BlogIndex.vue'),
			props: (route) => {
				return { ...route.params, isLandingPage: true };
			}
		},
		{
			path: ':cfid/*',
			name: 'blog-article',
			component: () => import(/* webpackChunkName: 'Blog' */ './BlogIndex.vue'),
			props: true
		},
		{ path: '*', redirect: '/blog' }
	]
}];