import createApp from './main';

const root = document.getElementById('app');
const isPrerendered = root?.innerHTML !== '' ?? false;
const isProd = process.env.NODE_ENV !== 'development';

const { app, router } = createApp({});

// wait until router is ready before mounting to ensure hydration match
router.onReady(() => {
	app.$mount('#app');
});

if (!isProd)
	console.log(isPrerendered ? 'hydrated' : 'rendered');