export default [{
	path: '/:amp(amp)?/industries',
	component: () => import(/* webpackChunkName: 'IndustryGuidesHome' */ './IndustryGuidesHome.vue'),
	props: true,
	children: [
		{
			path: '',
			name: 'industry-guides',
			component: () => import(/* webpackChunkName: 'IndustryGuidesIndex' */ './IndustryGuidesIndex.vue'),
			props: true
		},
		{
			path: ':cfid/*',
			name: 'industry-guide',
			component: () => import(/* webpackChunkName: 'IndustryGuide' */ './IndustryGuide.vue'),
			props: true
		},
		{ path: '*', redirect: '/industries' }
	]
}];